<template>
  <div id="number-input" class="flex end-xs">
    <div :class="isProductPage ? 'product-page-counter counter' : 'default-counter counter'">
      <!-- <label class="base-input-number__label cl-primary flex" :for="getInputId">{{ name }}</label> -->
      <i @click="$emit('click', decreaseValue)">&#x2212;</i>
      <label>
        <span class="visually-hidden">{{ inputValue }}</span>
        <input
          :id="getInputId"
          type="number"
          :min="min"
          :max="max"
          :disabled="disabled"
          class="m0 no-outline base-input-number__input brdr-cl-primary bg-cl-transparent h4"
          :focus="autofocus"
          v-model="inputValue"
          @blur="$emit('blur', $event.target.value)"
        >
      </label>
      <i @click="$emit('click', increaseValue)">&#43;</i>
      <ValidationMessages v-if="validations" :validations="validations" />
    </div>
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'
export default {
  name: 'BaseInput',
  components: {
    ValidationMessages
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    isProductPage: {
      type: Boolean,
      default: false
    },
    onlyPositive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getInputId () {
      return `input_${this._uid}`
    },
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        if (!this.onlyPositive) {
          this.$emit('input', value)
        } else {
          const targetValue = parseInt(value, 10)
          if (!isNaN(targetValue)) {
            this.$emit('input', targetValue !== 0 ? Math.abs(targetValue) : 1)
          }
        }
      }
    },
    decreaseValue () {
      var newValue = this.value
      if (newValue < 2) {
        newValue = 1
      } else {
        newValue -= 1
      }
      return newValue
    },
    increaseValue () {
      var newValue = this.value
      if (newValue < 99) {
        newValue += 1
      } else {
        newValue = 99
      }
      return newValue
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-main: color(main);
$color-matterhorn: color(matterhorn);

@media (min-width: 768px) {
  .product-page-counter {
    border: 1px solid #1396EC;
    input[type="number"] {
      // border-left: 2px solid #1396EC !important;
      // border-right: 2px solid #1396EC !important;
    }
  }
  .default-counter {
    border: 1px solid $color-main;
    input[type="number"] {
      // border-left: 2px solid $color-main !important;
      // border-right: 2px solid $color-main !important;
    }
  }
  // .counter {
  //   input[type="number"] {
  //     height: 26px !important;
  //     line-height: 30px !important;
  //     width: 26px !important;
  //     font-size: 20px !important;
  //   }
  //   i.material-icons {
  //     font-size: 26px !important;
  //     height: 26px !important;
  //     width: 28px !important;
  //   }
  // }
  // .product-page-counter {
  //   input[type="number"] {
  //     height: 26px !important;
  //     line-height: 30px !important;
  //     width: 26px !important;
  //     font-size: 16px !important;
  //   }
  //   i.material-icons {
  //     font-size: 26px !important;
  //     height: 26px !important;
  //     width: 28px !important;
  //   }
  // }
}
div#number-input {
  .product-page-counter {
    border: 1px solid #1396EC;
    input[type="number"] {
      // border-left: 2px solid #1396EC !important;
      // border-right: 2px solid #1396EC !important;
      color: $color-main;
      font-size: 18px;
    }
     i {
      color: $color-main;
    }
  }
  .default-counter {
    border: 2px solid $color-main;
    input[type="number"] {
      color: $color-main;
      border-left: 2px solid $color-main !important;
      border-right: 2px solid $color-main !important;
    }
    i {
      color: $color-main;
    }
  }
  .counter {
    display: flex;
    // background: white;
   // border: 2px solid $color-main;
    border-radius: 26px;
    align-items: center;
    input[type="number"] {
     // border-left: 2px solid $color-main !important;
     // border-right: 2px solid $color-main !important;
      border: 0;
      text-align: center;
      height: 26px;
      padding: 0;
      line-height: 30px;
      font-size: 16px;
      width: 30px;
      border-radius: 0;
    }
    input[type="number"]:focus,
    i:focus {
      outline: none;
    }
    i {
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      height: 26px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  // .product-page-counter {
  //   display: flex;
  //   background: white;
  //   align-items: center;
  //   input[type="number"] {
  //     border: 0;
  //     text-align: center;
  //     height: 26px;
  //     padding: 0;
  //     line-height: 30px;
  //     font-size: 22px;
  //     width: 30px;
  //     color: $color-main;
  //     border-radius: 0;

  //   }
  //   input[type="number"]:focus,
  //   i:focus {
  //     outline: none;
  //   }
  //   i {
  //     border: 2px solid $color-matterhorn;
  //     border-radius: 50%;
  //     padding: 0;
  //     color: $color-matterhorn;
  //     font-size: 26px;
  //     font-weight: 400;
  //     text-align: center;
  //     height: 30px;
  //     width: 30px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-around;
  //     cursor: pointer;
  //     -webkit-touch-callout: none;
  //     -webkit-user-select: none;
  //     -moz-user-select: none;
  //     -ms-user-select: none;
  //     user-select: none;
  //   }
  //   input::-webkit-outer-spin-button,
  //   input::-webkit-inner-spin-button {
  //     -webkit-appearance: none;
  //     margin: 0;
  //   }
  //   input[type="number"] {
  //     -moz-appearance: textfield;
  //   }
  // }
}
</style>
